import { Controller } from "@hotwired/stimulus"
import { debounce } from "../utils/debounce";
import MicroModal from "micromodal";

export default class extends Controller {
  static targets = ["company", "contact", "newContactOption", "newCompanyOption"]

  static values = {
    accountId: String,
    companies: { type: Array, default: [] },
    selectedCompany: String,
    contacts: { type: Array, default: [] },
    selectedContact: String,
  }

  connect(){
    this.debouncedSelectCompany = debounce(this.selectCompany.bind(this), 500);

    if (this.companyTarget.value === "") {
      this.reinitContactSelector();
    } else {
      this.fetchContacts();
    }

    if (this.contactTarget.value !== "") {
      this.fetchContact();
    }
  }

  /* Company behaviour */

  companyTargetConnected(element) {
    const companies = Array.from(element.options)
      .filter(option => option.value !== "")
      .map(option => ({
        value: option.value,
        name: option.text
      }));
    this.setCompaniesValue(companies);
    this.reinitCompanySelector();
  }

  setCompaniesValue(companies) {
    this.companiesValue = companies.sort((a, b) => a.value - b.value);
  }

  reinitCompanySelector() {
    // Destroy the current Selectize object, if it exists
    let selectizeControl = this.companyTarget.selectize;
    if (selectizeControl) { selectizeControl.destroy(); }

    $(this.companyTarget).selectize({
      options: this.companiesValue,
      plugins: ["clear_button"],
      onChange: (value) => {
        this.companyTarget.dispatchEvent(new Event('change'));
        this.selectCompany(value);
      },
      labelField: 'name',
      searchField: ['name'],
      sortField: 'name',
      create: (input) => {
        return { value: input, name: input, isNew: true };
      },
      createFilter: (input) => {
        return (input.length > 0 && !this.companiesValue.some(company => company.name.toLowerCase() === input.toLowerCase()));
      },
      render: {
        option_create: function(data, escape) {
          return '<div class="create">Add <strong>' + escape(data.input) + '</strong> as company →</div>';
        }
      },
      onDropdownOpen: () => {
        if (this.contactTarget.selectize) {
          this.contactTarget.selectize.close();
        }
      },
      onOptionAdd: (value, data) => {
        if (data.isNew) {
          this.handleNewCompany(value);
          this.companyTarget.selectize.removeOption(value);
          this.companyTarget.selectize.refreshOptions(false);
          this.companyTarget.selectize.clear();
        }
      },
      onClear: function() {
        this.close();
      }
    });
    this.selectDefaultCompany();
  }

  selectDefaultCompany() {
    const optionKeys = Object.keys(this.companyTarget.selectize.options).reverse();
    if (optionKeys.length > 1) {
      const latestOptionIndex = optionKeys.findIndex(key => this.companyTarget.selectize.options[key].latest === true);
      if (latestOptionIndex > -1) {
        this.companyTarget.selectize.setValue(optionKeys[latestOptionIndex]);
        this.companyTarget.selectize.trigger('change');
      }
    }
  }

  handleNewCompany(value) {
    const role = this.companyTarget.dataset.role;
    document.querySelector(`#${role}-company input[name="company[name]"]`).value = value;
    MicroModal.show(`${role}-company`);
  }

  newCompanyOptionTargetConnected(element) {
    const role = this.companyTarget.dataset.role;

    const newCompany = {
      value: element.value,
      name: element.dataset.name,
      latest: true
    };

    this.setCompaniesValue([...this.companiesValue, newCompany]);
    this.reinitCompanySelector();

    MicroModal.close(`${role}-company`);
  }

  selectCompany(event) {
    this.clearContactDetails();
    this.updateContactCompanyId();
    this.fetchContacts();
  }

  async fetchContacts() {
    if (this.companyTarget.value === ""){
      this.setContactsValue([]);
      return;
    }

    try {
      const response = await fetch(`/a/${this.accountIdValue}/companies/${this.companyTarget.value}/contacts.json`);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      const contacts = data.map(contact => ({
        value: contact.id,
        name: contact.full_name,
        company: contact.company_name,
        company_id: contact.company_id
      }));
      this.setContactsValue(contacts);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  }

  /* Contact behaviour */

  setContactsValue(contacts) {
    // NB - Selectize value goes by index of the options so we need to ensure options and ids are in order
    this.contactsValue = contacts.sort((a, b) => a.value - b.value);
    this.reinitContactSelector();
  }

  clearContactDetails() {
    this.contactTarget.value = "";
    if (this.contactTarget.selectize) {
      this.contactTarget.selectize.clear();
      this.contactTarget.selectize.destroy();
    }
    this.contactTarget.querySelectorAll('option:not([value=""])').forEach(option => option.remove());
    this.setContactsValue([]);
  }

  updateContactCompanyId() {
    const companyId = this.companyTarget.value;
    this.contactTarget.dataset.companyId = companyId;
  }

  fetchContact() {
    if (this.contactTarget.value == "") {
      return;
    }

    fetch(`/a/${this.accountIdValue}/contacts/${this.contactTarget.value}.json`)
      .then(response => response.json())
      .then(data => {
        this.dispatch("contact-selected", { detail: { contact: data } });
      });
  }

  handleNewContact(value) {
    const companyId = this.companyTarget.value;
    const companyName = this.companyTarget.options[this.companyTarget.selectedIndex].text;
    const [firstName, ...lastNameParts] = value.split(' ');
    const lastName = lastNameParts.join(' ');
    const role = this.companyTarget.dataset.role;

    MicroModal.show(`${role}-contact`);

    document.querySelector(`#${role}-contact input[name="contact[first_name]"]`).value = firstName;
    document.querySelector(`#${role}-contact input[name="contact[last_name]"]`).value = lastName;
    document.querySelector(`#${role}-contact input[name="contact[company_id]"]`).value = companyId;
  }

  selectContact(event) {
    const selectedOption = this.contactTarget.selectize.options[event];
    this.dispatch("contact-selected", { detail: { contact: {} } });

    if (selectedOption && selectedOption.isNew) {
      console.debug("New contact selected");
    } else {
      this.fetchContact();
    }
  }

  reinitContactSelector() {
    // Destroy the current Selectize object, if it exists
    const currentValue = this.contactTarget.value;
    let selectizeControl = this.contactTarget.selectize;
    if (selectizeControl) { selectizeControl.destroy(); }

    $(this.contactTarget).selectize({
      options: this.contactsValue,
      plugins: ["clear_button"],
      optionGroupRegister: function (optgroup) {
        var capitalised = optgroup.charAt(0).toUpperCase() + optgroup.substring(1);
        var group = {
          label: 'Company: ' + capitalised
        };
        group[this.settings.optgroupValueField] = optgroup;
        return group;
      },
      onChange: this.selectContact.bind(this),
      optgroupField: 'company',
      labelField: 'name',
      searchField: ['name'],
      sortField: 'name',
      create: (input) => {
        return { value: input, name: input, isNew: true };
      },
      createFilter: (input) => {
        const companySelected = this.companyTarget.value !== "";
        const newContactInput = input.length > 0 && !this.contactsValue.some(contact => contact.name.toLowerCase() === input.toLowerCase());
        return companySelected && newContactInput;
      },
      render: {
        option_create: function(data, escape) {
          return '<div class="create">Add <strong>' + escape(data.input) + '</strong> as contact →</div>';
        }
      },
      onDropdownOpen: () => {
        if (this.companyTarget.selectize) {
          this.companyTarget.selectize.close();
        }
      },
      onOptionAdd: (value, data) => {
        if (data.isNew) {
          this.handleNewContact(value);
          this.contactTarget.selectize.removeOption(value);
          this.contactTarget.selectize.refreshOptions(false);
          this.contactTarget.selectize.clear();
        }
      },
      onClear: function() {
        this.close();
      }
    });

    this.updateContactCompanyId();
    this.selectDefaultContact(currentValue);
  }

  selectDefaultContact(currentValue) {
    const contactsNotYetLoaded = this.contactsValue.length === 0;
    if (contactsNotYetLoaded) { return; }

    const compatibleContactIndex = this.contactsValue.findIndex(contact => contact.value.toString() === currentValue && contact.company_id.toString() === this.companyTarget.value);
    if (compatibleContactIndex > -1) {
      this.contactTarget.selectize.setValue(currentValue);
      return;
    }

    const optionKeys = Object.keys(this.contactTarget.selectize.options).reverse();
    if (optionKeys.length === 1) {
      this.contactTarget.selectize.setValue( optionKeys[0] );
      this.contactTarget.selectize.trigger('change');
    } else {
      const latestOptionIndex = optionKeys.findIndex(key => this.contactTarget.selectize.options[key].latest === true);
      if (latestOptionIndex > -1) {
        this.contactTarget.selectize.setValue(optionKeys[latestOptionIndex]);
        this.contactTarget.selectize.trigger('change');
      }
    }
  }

  newContactOptionTargetConnected(element) {
    const role = this.companyTarget.dataset.role;
    MicroModal.close(`${role}-contact`);

    const newContact = {
      value: element.value,
      name: element.dataset.fullName,
      company: element.dataset.companyName,
      latest: true
    };
    this.setContactsValue([...this.contactsValue, newContact]);
  }
}