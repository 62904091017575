import { Controller } from "@hotwired/stimulus"
import Papa from "papaparse"

export default class extends Controller {
  static targets = ["fileInput", "table", "actionSelect", "empty", "filename", "count", "importButton", "key"]
  static values = {
    model: String,
    fields: Array
  }

  initialize() {
    this.selectedRows = new Set()
    this.headers = []
    this.showErrorRows = true
  }

  connect() {
    this.originalData = null
    this.setupEventListeners()
    this.updateButtonText()
    this.stripCurrencyEnabled = false
    this.keyColumnIndex = null
  }

  disconnect() {
    this.removeEventListeners()
  }

  setupEventListeners() {
    this.boundHandleFileSelect = this.handleFileSelect.bind(this)
    this.boundUpdateFieldOptions = this.updateFieldOptions.bind(this)
    this.boundHandleImport = this.handleImport.bind(this)
    this.boundStripCurrency = this.stripCurrency.bind(this)
    this.boundKeySelect = this.handleKeySelect.bind(this)

    this.fileInputTarget.addEventListener("change", this.boundHandleFileSelect)
    this.importButtonTarget.addEventListener("click", this.boundHandleImport)
    this.keyTarget.addEventListener("change", this.boundKeySelect)
  }

  removeEventListeners() {
    this.fileInputTarget.removeEventListener("change", this.boundHandleFileSelect)
    this.importButtonTarget.removeEventListener("click", this.boundHandleImport)
    this.keyTarget.removeEventListener("change", this.boundKeySelect)
  }

  stripCurrency(event) {
    this.stripCurrencyEnabled = event.target.checked
    if (this.originalData) {
      this.renderTableBody(this.originalData.slice(1))
    }
  }

  handleFileSelect(event) {
    const file = event.target.files[0]

    if (file && (file.type === "text/csv" || file.type === "text/tab-separated-values")) {
      this.filenameTarget.textContent = file.name
      this.parseCSV(file)
    } else {
      this.showAlert("Please upload a valid CSV file.")
    }
  }

  parseCSV(file) {
    Papa.parse(file, {
      skipEmptyLines: false, // Change this to false to include empty lines
      complete: this.handleParseComplete.bind(this),
      error: this.handleParseError.bind(this)
    })
  }

  handleParseComplete(results) {
    const { data } = results
    if (!data.length) {
      this.generateEmptyTable()
      return
    }

    this.headers = data[0]
    const headerCount = this.headers.length

    this.originalData = data.map((row, index) => ({
      data: this.padRow(row, headerCount),
      isValid: index === 0 || (row.length === headerCount && row.some(cell => cell.trim() !== ""))
    }))

    this.originalData.length <= 1 ? this.generateEmptyTable() : this.generateTable(this.originalData)

    this.updateRowCounts()
    this.updateKeyOptions()
  }

  handleParseError(error) {
    console.error("Error parsing CSV:", error)
    this.showAlert("An error occurred while parsing the CSV file.")
  }

  updateRowCounts() {
    const goodRows = this.originalData.slice(1).filter(row => row.isValid).length
    const badRows = this.originalData.length - 1 - goodRows

    if (this.showErrorRows) {
      this.countTarget.innerHTML = `(${goodRows} valid, ${badRows} invalid)`
    } else {
      this.countTarget.innerHTML = `(${goodRows})`
    }
  }

  generateEmptyTable() {
    this.emptyTarget.textContent = "This CSV is not valid, please try again"
    this.emptyTarget.classList.remove("hidden")
    this.tableTarget.classList.add("hidden")
  }

  generateTable(data) {
    const table = this.tableTarget
    table.classList.remove("hidden")
    this.emptyTarget.classList.add("hidden")

    this.renderTableHeader(this.headers)
    this.renderTableBody(data.slice(1))

    this.updateFieldOptions()
    this.updateButtonText()
  }

  renderTableHeader(headerRow) {
    const thead = this.tableTarget.querySelector("thead")
    thead.innerHTML = ""
    const headerRowElement = document.createElement("tr")
    headerRowElement.appendChild(this.createCheckboxCell(true))

    headerRow.forEach((header, index) => {
      const th = document.createElement("th")
      th.appendChild(this.createHeaderContent(header, index))
      headerRowElement.appendChild(th)
    })

    thead.appendChild(headerRowElement)
  }

  renderTableBody(bodyRows) {
    const tbody = this.tableTarget.querySelector("tbody")
    tbody.innerHTML = ""
    bodyRows.forEach((row, index) => {
      const tr = document.createElement("tr")
      if (!row.isValid) {
        tr.classList.add("bg-red-100", "error-row")
        if (!this.showErrorRows) {
          tr.classList.add("hidden")
        }
      }
      tr.appendChild(this.createCheckboxCell(false, index + 1, row.isValid))
      row.data.forEach(cell => {
        const td = document.createElement("td")
        td.textContent = this.stripCurrencyEnabled ? this.stripCurrencyFromCell(cell) : cell
        tr.appendChild(td)
      })
      tbody.appendChild(tr)
    })

    this.restoreCheckboxStates()
  }

  padRow(row, desiredLength) {
    if (row.length >= desiredLength) {
      return row
    }
    return [...row, ...Array(desiredLength - row.length).fill("")]
  }

  restoreCheckboxStates() {
    this.tableTarget.querySelectorAll("tbody input[type='checkbox']").forEach((checkbox, index) => {
      if (this.selectedRows.has(index + 1)) {
        checkbox.checked = true
      }
    })
  }

  stripCurrencyFromCell(cell) {
    return cell.replace(/[$€£¥₹₽₩₦₱₿]/g, '')
  }

  createCheckboxCell(isHeader, index, isValid = true) {
    const cell = document.createElement(isHeader ? "th" : "td")
    cell.classList.add("w-6")
    const checkbox = document.createElement("input")
    checkbox.type = "checkbox"
    if (isHeader) {
      checkbox.dataset.action = "change->importer#toggleAll"
    } else {
      checkbox.id = index.toString()
      checkbox.dataset.action = "change->importer#toggleRowSelection"
      if (!isValid) {
        checkbox.disabled = true
        checkbox.title = "This row is invalid and cannot be imported"
        checkbox.classList.add("opacity-25","cursor-not-allowed")
      }
    }
    cell.appendChild(checkbox)
    return cell
  }

  toggleRowSelection(event) {
    const checkbox = event.target
    const rowIndex = parseInt(checkbox.id)

    if (checkbox.checked && !checkbox.disabled) {
      this.selectedRows.add(rowIndex)
    } else {
      this.selectedRows.delete(rowIndex)
    }

    this.updateButtonText()
  }

  toggleErrorRows(event) {
    this.showErrorRows = event.target.checked
    const errorRows = this.tableTarget.querySelectorAll(".error-row")
    errorRows.forEach(row => {
      if (this.showErrorRows) {
        row.classList.remove("hidden")
      } else {
        row.classList.add("hidden")
      }
    })
    this.updateRowCounts()
  }

  createHeaderContent(header, index) {
    const container = document.createElement("div")
    container.classList.add("flex", "flex-col", "items-start")

    const columnName = document.createElement("div")
    columnName.classList.add("font-300", "text-xs", "text-text", "mb-1")
    columnName.textContent = header
    container.appendChild(columnName)

    const fieldSelect = this.createFieldSelect(header)
    container.appendChild(fieldSelect)

    return container
  }

  createFieldSelect(header) {
    const fieldSelect = document.createElement("select")
    fieldSelect.dataset.action = "change->importer#updateKeyOptions"

    const emptyOption = document.createElement("option")
    emptyOption.value = ""
    emptyOption.textContent = "Select an option"
    fieldSelect.appendChild(emptyOption)

    const options = this.fieldsValue

    options.forEach((optionText) => {
      const option = document.createElement("option")
      option.value = optionText
      option.textContent = optionText
      fieldSelect.appendChild(option)
    })

    this.autoSelectOption(fieldSelect, header)

    return fieldSelect
  }

  autoSelectOption(select, header) {
    const normalizedHeader = header.trim().toLowerCase();
    const options = this.fieldsValue

    // Generate matches dynamically based on the fields
    const matches = options.reduce((acc, field) => {
      const keywords = [
        field.toLowerCase(),
        field.replace(/\s+/g, '').toLowerCase(),
        ...field.toLowerCase().split(/\s+/)
      ];
      acc[field] = keywords;
      return acc;
    }, {});

    // Get the actual options available in the select element
    const availableOptions = Array.from(select.options).map(option => option.value);

    for (let [optionText, keywords] of Object.entries(matches)) {
      if (availableOptions.includes(optionText)) {
        if (keywords.some(keyword => normalizedHeader.includes(keyword))) {
          select.value = optionText;
          return;
        }
      }
    }

    // If no match is found, set the value to "Ignore"
    select.value = "Ignore";
  }

  updateFieldOptions() {
    const selects = this.tableTarget.querySelectorAll("thead select")

    this.updateButtonText()

    selects.forEach((select) => {
      const initialOptions = ["Ignore"]
      const additionalOptions = this.fieldsValue

      let options = [...initialOptions, ...additionalOptions]

      select.innerHTML = ""
      options.forEach((optionText) => {
        const option = document.createElement("option")
        option.value = optionText
        option.textContent = optionText
        select.appendChild(option)
      })

      this.autoSelectOption(select, select.previousElementSibling.textContent)
    })

    this.updateKeyOptions()
  }

  updateKeyOptions() {
    const keySelect = this.keyTarget
    keySelect.innerHTML = '<option value="">Select a key column</option>'

    const options = this.fieldsValue

    options.forEach((field, index) => {
      const option = document.createElement('option')
      option.value = index
      option.textContent = field
      keySelect.appendChild(option)
    })

    keySelect.disabled = options.length === 0
  }

  handleKeySelect(event) {
    this.keyColumnIndex = parseInt(event.target.value)
  }

  toggleAll(event) {
    const { checked } = event.target
    this.tableTarget.querySelectorAll("tbody input[type='checkbox']:not(:disabled)").forEach((checkbox, index) => {
      checkbox.checked = checked
      if (checked) {
        this.selectedRows.add(index + 1)
      } else {
        this.selectedRows.delete(index + 1)
      }
    })
    this.updateButtonText()
  }

  updateButtonText() {
    const action = this.actionSelectTarget.value
    const checkedCount = this.selectedRows.size

    if(checkedCount) {
      this.importButtonTarget.textContent = `${action} ${checkedCount} items`
      this.importButtonTarget.classList.remove("opacity-50", "pointer-events-none")
    } else {
      this.importButtonTarget.textContent = `${action} 0 items`
      this.importButtonTarget.classList.add("opacity-50", "pointer-events-none")
    }
  }

  async handleImport() {
    if (!this.originalData) {
      this.showAlert("No data has been uploaded.")
      return
    }

    if (this.keyColumnIndex === null) {
      this.showAlert("Please select a key column.")
      return
    }

    const originalButtonText = this.importButtonTarget.textContent
    this.importButtonTarget.textContent = "Processing..."
    this.importButtonTarget.disabled = true

    try {
      const importData = await this.prepareImportData()
      console.log(JSON.stringify(importData, null, 2))
      // Here you would typically send the data to your server
      // For example: await fetch('/import', { method: 'POST', body: JSON.stringify(importData) })

      this.showAlert("Import completed successfully!")
    } catch (error) {
      console.error("Import error:", error)
      this.showAlert(`An error occurred during import: ${error.message}. Please try again.`)
    } finally {
      this.importButtonTarget.disabled = false
      this.updateButtonText()
    }
  }

  async prepareImportData() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        try {
          const headers = Array.from(this.tableTarget.querySelectorAll("thead select")).map(select => select.value)
          const options = this.fieldsValue
          const keyField = options[this.keyColumnIndex]

          if (headers[this.keyColumnIndex] === "Ignore") {
            throw new Error("The selected key field is set to Ignore. Please select a valid key field.")
          }

          const validHeaderIndices = headers.reduce((indices, header, index) => {
            if (header !== "Ignore") indices.push(index)
            return indices
          }, [])

          // Filter out the header row and empty rows, then map selected rows to their data
          const selectedDataRows = this.originalData.slice(1) // Exclude header row
            .filter((row, index) =>
              row.isValid && // Exclude invalid (empty) rows
              this.selectedRows.has(index + 1) // Check if the row is selected (add 1 because selectedRows is 1-indexed)
            )

          if (selectedDataRows.length === 0) {
            throw new Error("No valid rows selected for import.")
          }

          const importData = {
            model: this.modelValue,
            keyField: keyField,
            fields: validHeaderIndices.map(index => headers[index]),
            data: selectedDataRows.map(row =>
              validHeaderIndices.map(colIndex => {
                const cellValue = row.data[colIndex]
                return this.stripCurrencyEnabled ? this.stripCurrencyFromCell(cellValue) : cellValue
              })
            )
          }

          resolve(importData)
        } catch (error) {
          reject(error)
        }
      }, 0)
    })
  }

  showAlert(message) {
    alert(message) // Consider replacing with a more user-friendly notification system
  }
}