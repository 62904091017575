import { Controller } from "@hotwired/stimulus"
import MicroModal from "micromodal";

export default class extends Controller {
  static targets = ["addressFieldsReadOnly", "addressSelector", "newAddressOption", "addressCycler", "addressIndex", "addressCount"]

  static values = {
    accountId: String,
    addresses: { type: Array, default: [] },
    contact: Object
  }

  contactValueChanged() {
    this.addressFieldsReadOnlyTarget.classList.toggle('hidden', (Object.keys(this.contactValue).length < 1));
  }

  handleContactSelected(event) {
    this.setContact(event.detail.contact);
  }

  setContact(contact) {
    this.contactValue = contact;
    this.addressesValue = contact.addresses || [];
    this.initCurrentAddress();
  }

  initCurrentAddress() {
    const select = this.addressSelectorTarget;
    select.innerHTML = '<option value="">Select...</option>';
    select.setAttribute('data-contact-id', this.contactValue.id);

    this.addressesValue.forEach((address) => {
      const option = document.createElement('option');
      option.value = address.id;
      option.textContent = this.formatAddressOption(address);
      select.appendChild(option);
    });

    const initialAddressId = this.addressSelectorTarget.dataset.initialAddressId;
    if (initialAddressId && this.addressesValue.some(addr => addr.id.toString() === initialAddressId)) {
      this.addressSelectorTarget.value = initialAddressId;
    } else if (this.addressesValue.length > 0) {
      this.addressSelectorTarget.value = this.addressesValue[0].id.toString();
    }

    this.renderAddress();
    this.renderAddressCycler();
  }

  formatAddressOption(address) {
    return `${address.line_one}, ${address.town}, ${address.country}`;
  }

  selectAddress(event) {
    const selectedAddressId = this.addressSelectorTarget.value;
    this.addressSelectorTarget.dataset.initialAddressId = '';
    this.renderAddress();
    this.renderAddressCycler();
  }

  renderAddress() {
    const selectedAddressId = this.addressSelectorTarget.value;
    const currentAddress = this.addressesValue.find(address => address.id.toString() === selectedAddressId) || {};
    const addressInfo = this.addressFieldsReadOnlyTarget.querySelector('div.address-info');
    addressInfo.innerHTML = this.formatAddress(currentAddress); // Changed from innerText to innerHTML

    const clearAddressButton = this.addressFieldsReadOnlyTarget.querySelector('[data-action="click->address-population#clearAddress"]');
    if (clearAddressButton) {
      if (selectedAddressId) {
        clearAddressButton.classList.remove('hidden');
      } else {
        clearAddressButton.classList.add('hidden');
      }
    }
  }

  formatAddress(address) {
    if (Object.keys(address).length === 0) {
      if (this.addressesValue.length > 0) {
        return `<button class="cursor-pointer" data-action="click->address-population#cycleAddress" data-cycleby="0">
          No address provided. <span class="text-accent">${this.addressesValue.length} available →</span>
        </button>`;
      }
      return 'No address provided';
    }

    return address.formatted_address ||
      Object.entries(address)
        .filter(([key, value]) => value !== '' && value !== null && key !== 'id')
        .map(([_, value]) => value)
        .join('<br/>');
  }

  clearAddressDetails() {
    this.addressesValue = [];
    this.addressSelectorTarget.value = "";
  }

  addAddress(event) {
    const formUrl = `/a/${this.accountIdValue}/contacts/${this.contactValue.id}/addresses`;
    const modal = event.currentTarget.dataset.modal;
    const form = document.querySelector(`#${modal} form`);
    form.action = formUrl;
    MicroModal.show(modal);
  }

  newAddressOptionTargetConnected(element) {
    const newAddress = {
      id: element.value,
      line_one: element.dataset.lineOne,
      line_two: element.dataset.lineTwo,
      town: element.dataset.town,
      county: element.dataset.county,
      post_code: element.dataset.postCode,
      country: element.dataset.country
    };
    this.addressesValue = [...this.addressesValue, newAddress];

    this.addressSelectorTarget.value = element.value;
    this.addressSelectorTarget.dispatchEvent(new Event('change'));

    MicroModal.close('supplier-address');
    MicroModal.close('recipient-address');
  }

  renderAddressCycler() {
    const addressCount = this.addressesValue.length;
    const selectedAddressId = this.addressSelectorTarget.value;

    if (addressCount > 0) {
      this.addressCountTarget.textContent = addressCount;

      if (selectedAddressId) {
        // An address is selected
        this.addressCyclerTarget.classList.remove('hidden');
        const currentIndex = this.addressesValue.findIndex(address => address.id.toString() === selectedAddressId) + 1;
        this.addressIndexTarget.textContent = currentIndex;
      } else {
        // No address selected, but addresses are available
        this.addressCyclerTarget.classList.add('hidden');
      }

      if (addressCount === 1) {
        this.addressCyclerTarget.querySelectorAll("button").forEach(button => button.setAttribute('class', 'pointer-events-none px-1 opacity-40'));
      } else {
        this.addressCyclerTarget.querySelectorAll("button").forEach(button => button.setAttribute('class', 'rounded px-1 cursor-pointer hover:bg-gray-200'));
      }
    } else {
      this.addressCyclerTarget.classList.add('hidden');
    }
  }

  cycleAddress(event) {
    const direction = parseInt(event.currentTarget.dataset.cycleby);
    const addressCount = this.addressesValue.length;
    let currentIndex = this.addressesValue.findIndex(address => address.id.toString() === this.addressSelectorTarget.value);

    if (currentIndex === -1) currentIndex = 0;

    let newIndex = (currentIndex + direction + addressCount) % addressCount;

    this.addressSelectorTarget.value = this.addressesValue[newIndex].id;
    this.addressIndexTarget.textContent = newIndex + 1;
    this.renderAddress();
    this.renderAddressCycler();
  }

  clearAddress(event) {
    this.addressSelectorTarget.value = "";
    this.renderAddress();
    this.renderAddressCycler();
  }
}