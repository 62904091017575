import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { debounce } from "../utils/debounce";

export default class extends Controller {
  static targets = [
    "supplier", "accountNumber", "lineItemDescription", "lineItemSku",
    "currency", "products"
  ]

  static values = {
    accountId: String,
    supplierProducts: Array
  }

  static outlets = [ "line-item" ]

  lineItemOutletConnected(outlet) {
    outlet.supplierProductsValue = this.supplierProductsValue;
    this.itemAmountChanged();
  }

  lineItemOutletDisconnected() {
  }

  connect() {
    this.debouncedFetchSupplier = debounce(this.fetchSupplier.bind(this), 500);
    this.fetchSupplier();
    this.initializeCurrencyTip();
    this.initializeSupplierTip();
  }

  initializeSupplierTip() {
    this.supplierTip = tippy(this.supplierTarget.parentElement, {
      trigger: 'mouseenter',
      content: "You must remove line items before changing supplier",
      duration: [0, 1000],
      hideOnClick: true,
      interactive: true,
      showOnCreate: false
    });
    this.supplierTip.disable();
  }

  lockSupplier = (isLocked) => {
    this.supplierTarget.classList.toggle("pointer-events-none", isLocked);
    this.supplierTarget.classList.toggle("bg-gray-100", isLocked);
    if (this.supplierTip) {
      isLocked ? this.supplierTip.enable() : this.supplierTip.disable();
    }
  }

  initializeCurrencyTip() {
    this.currencyTip = tippy(this.currencyTarget.parentElement, {
      trigger: 'mouseenter',
      content: "You must remove line items before changing currency",
      duration: [0, 1000],
      hideOnClick: true,
      interactive: true,
      showOnCreate: false
    });
    this.currencyTip.disable();
  }

  lockCurrency = (isLocked) => {
    this.currencyTarget.classList.toggle("pointer-events-none", isLocked);
    this.currencyTarget.classList.toggle("bg-gray-100", isLocked);
    if (this.currencyTip) {
      isLocked ? this.currencyTip.enable() : this.currencyTip.disable();
    }
  }

  itemAmountChanged = () => {
    const activeItems = this.lineItemOutlets.filter(item => item.destroyTarget.value !== "1");
    this.lockCurrency(activeItems.length > 0);
    this.lockSupplier(activeItems.length > 0);
  }

  fetchSupplier = async () => {
    if (this.supplierTarget.value !== "") {
      try {
        const response = await fetch(`/a/${this.accountIdValue}/companies/${this.supplierTarget.value}.json`);
        const data = await response.json();
        this.populateAccountNumber(data);
        this.populateCurrency(data);
        await this.fetchSupplierItemsAndProducts();
      } catch (error) {
        console.error('Error fetching supplier data:', error);
      }
    }
  }

  fetchSupplierItemsAndProducts = async () => {
    try {
      const [products, lineItems] = await Promise.all([
        this.fetchSupplierProducts(),
        this.fetchSupplierItems()
      ]);

      const combinedData = [
        ...products.map(p => ({ ...p, source: "Product" })),
        ...lineItems.map(p => ({ ...p, source: "Purchase" }))
      ];

      this.populateLineItemDescriptions(combinedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  fetchSupplierProducts = async () => {
    const response = await fetch(`/a/${this.accountIdValue}/companies/${this.supplierTarget.value}/products.json`);
    return await response.json();
  }

  fetchSupplierItems = async () => {
    const response = await fetch(`/a/${this.accountIdValue}/companies/${this.supplierTarget.value}/supplied_items.json`);
    return await response.json();
  }

  populateLineItemDescriptions(data) {
    const productOptions = data.map(product => {
      const { id, source, name, currency } = product;
      const productInfo = product.purchase_costs?.[this.currencyTarget.value]?.find(pc => pc.company_id === Number(this.supplierTarget.value)) || {};
      const sku = productInfo?.sku || product.sku;
      const unitPrice = productInfo?.amount;

      return { id, source, name, sku, unitPrice, currency };
    });

    this.lineItemDescriptionTargets.forEach(box => {
      box.value = productOptions[0].name;
    });

    this.supplierProductsValue = productOptions;
    this.renderProductList(productOptions);
  }

  populateAccountNumber(data) {
    this.accountNumberTarget.value = data.account_number ?? '';
  }

  populateCurrency(data) {
    if (data.currency_id) {
      this.currencyTarget.value = data.currency_id;
    }
  }

  addItem() {
    MicroModal.show("po-products");
  }

  renderProductList(products) {
    this.allProducts = products || [];
    this.renderFilteredProducts(this.allProducts);
  }

  renderFilteredProducts(products) {
    this.productsTarget.innerHTML = products
      .filter(product => product.source === "Product")
      .map(product => `
        <div class="grid grid-cols-[2fr_1fr_4fr_1fr_auto] gap-4 items-center py-2">
          <span class="grow truncate">${product.sku}</span>
          <span class="grow">Nov 2023</span>
          <span class="grow truncate">${product.name}</span>
          <span class="price justify-self-end">£${product.unitPrice}</span>
          <button class="btn btn-primary" data-sku="${product.sku}">Add</button>
        </div>
      `)
      .join('');

    this.productsTarget.querySelectorAll('button').forEach(button => {
      button.addEventListener('click', this.handleButtonClick);
    });
  }

  searchProducts = (event) => {
    const searchQuery = event.target.value.toLowerCase();

    if (!this.allProducts) return;

    const filteredProducts = this.allProducts.filter(product =>
      product.source === "Product" && (
        product.name.toLowerCase().includes(searchQuery) ||
        product.sku.toLowerCase().includes(searchQuery)
      )
    );

    this.renderFilteredProducts(filteredProducts);
  }

  handleButtonClick = (event) => {
    const productSku = event.target.dataset.sku;
    const selectedProductObject = this.allProducts.find(product => product.sku === productSku);

    const nestedFormElement = document.querySelector('[data-controller="nested-form"]');
    const nestedFormController = this.application.getControllerForElementAndIdentifier(nestedFormElement, "nested-form");

    if (nestedFormController) {
      nestedFormController.add(event, selectedProductObject);
      this.itemAmountChanged();
    }
  }
}