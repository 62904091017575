// Entry point for the build script in your package.json
import { appsignal } from "./appsignal";
import '@selectize/selectize/dist/js/selectize.min.js';
import '@selectize/selectize/dist/css/selectize.default.css';

import MicroModal from 'micromodal';
import Trix from "trix";
import "@rails/actiontext"
import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;

require("@rails/ujs").start();

import "./channels"
import "@hotwired/turbo-rails"
import "./controllers"